<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div class="md-layout" style="margin-bottom: 20px;">
        <div v-if="userStatistics && userTabsStatistics && userTabCellsStatistics" class="md-layout-item md-size-33 md-small-size-33 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>Users</h2>
            </md-card-header>
            <md-card-content>
              <h3 v-if="userStatistics.count">{{ userStatistics.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Users</h3>
              <h3 v-if="userStatistics.usersWithNoTabsCount">{{ userStatistics.usersWithNoTabsCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Users with no tabs</h3>
              <h3 v-if="userTabsStatistics.count">{{ userTabsStatistics.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Tabs</h3>
              <h3 v-if="userTabsStatistics.tabsWithNoCellsCount">{{ userTabsStatistics.tabsWithNoCellsCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Tabs with no Cells</h3>
              <h3 v-if="userTabsStatistics.tabsWithNoMappedFilesCount">
                {{ userTabsStatistics.tabsWithNoMappedFilesCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Tabs with unmapped Files
              </h3>
              <h3 v-if="userTabCellsStatistics.count">{{ userTabCellsStatistics.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Tab Cells</h3>
              <h3 v-if="userTabCellsStatistics.siteId0">{{ userTabCellsStatistics.siteId0.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Tab Cells not linked to Sites</h3>
            </md-card-content>
          </md-card>
        </div>

        <div v-if="siteStatistics && unlistedSitesStatistics && mostPopularSitesStatistics" class="md-layout-item md-size-33 md-small-size-33 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>Sites</h2>
            </md-card-header>
            <md-card-content>
              <h3 v-if="siteStatistics.count">{{ siteStatistics.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Sites</h3>
              <h3 v-if="siteStatistics.notLinkedSites">{{ siteStatistics.notLinkedSites.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Sites Not Linked</h3>
              <h3 v-if="unlistedSitesStatistics.count">{{ unlistedSitesStatistics.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Unlisted Sites</h3>
              <h3 v-if="mostPopularSitesStatistics.count">{{ mostPopularSitesStatistics.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Most Popular Sites</h3>
            </md-card-content>
          </md-card>
        </div>

        <div v-if="favPagesStatistics && favLinesStatistics && favCellsStatistics" class="md-layout-item md-size-33 md-small-size-33 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>Fav Pages</h2>
            </md-card-header>
            <md-card-content>
              <h3 v-if="favPagesStatistics.count">{{ favPagesStatistics.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Fav Pages</h3>
              <h3 v-if="favLinesStatistics.count">{{ favLinesStatistics.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Fav Lines</h3>
              <h3 v-if="favLinesStatistics.notLinkedCount">{{ favLinesStatistics.notLinkedCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Fav Lines Not Linked</h3>
              <h3 v-if="favCellsStatistics.count">{{ favCellsStatistics.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} Fav Cells</h3>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <div class="md-layout" style="margin-bottom: 20px;">
        <div class="md-layout-item md-size-50 md-small-size-50 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>Users</h2>
            </md-card-header>
            <md-card-content>
              <line-chart :chartData="usersChartData" :chartOptions="chartOptions"/>
            </md-card-content>
          </md-card>
        </div>

        <div class="md-layout-item md-size-50 md-small-size-50 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>Users</h2>
            </md-card-header>
            <md-card-content>
              <bar-chart :chartData="usersChartData" :chartOptions="chartOptions"/>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <div class="md-layout" style="margin-bottom: 20px;">
        <div class="md-layout-item md-size-50 md-small-size-50 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>User Tabs</h2>
            </md-card-header>
            <md-card-content>
              <line-chart :chartData="userTabsChartData" :chartOptions="chartOptions"/>
            </md-card-content>
          </md-card>
        </div>

        <div class="md-layout-item md-size-50 md-small-size-50 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>User Tabs</h2>
            </md-card-header>
            <md-card-content>
              <bar-chart :chartData="userTabsChartData" :chartOptions="chartOptions"/>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <div class="md-layout" style="margin-bottom: 20px;">
        <div class="md-layout-item md-size-50 md-small-size-50 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>User Tab Cells</h2>
            </md-card-header>
            <md-card-content>
              <line-chart :chartData="userTabCellsChartData" :chartOptions="chartOptions"/>
            </md-card-content>
          </md-card>
        </div>

        <div class="md-layout-item md-size-50 md-small-size-50 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>User Tab Cells</h2>
            </md-card-header>
            <md-card-content>
              <bar-chart :chartData="userTabCellsChartData" :chartOptions="chartOptions"/>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <div class="md-layout" style="margin-bottom: 20px;">
        <div class="md-layout-item md-size-50 md-small-size-50 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>Sites</h2>
            </md-card-header>
            <md-card-content>
              <line-chart :chartData="sitesChartData" :chartOptions="chartOptions"/>
            </md-card-content>
          </md-card>
        </div>

        <div class="md-layout-item md-size-50 md-small-size-50 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>Sites</h2>
            </md-card-header>
            <md-card-content>
              <bar-chart :chartData="sitesChartData" :chartOptions="chartOptions"/>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <div class="md-layout" style="margin-bottom: 20px;">
        <div class="md-layout-item md-size-50 md-small-size-50 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>Fav Lines</h2>
            </md-card-header>
            <md-card-content>
              <line-chart :chartData="favLinesChartData" :chartOptions="chartOptions"/>
            </md-card-content>
          </md-card>
        </div>

        <div class="md-layout-item md-size-50 md-small-size-50 md-xsmall-size-100">
          <md-card>
            <md-card-header>
              <h2>Fav Lines</h2>
            </md-card-header>
            <md-card-content>
              <bar-chart :chartData="favLinesChartData" :chartOptions="chartOptions"/>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue';
import LineChart from '@/components/charts/LineChart.vue';
import { FETCH_ADMIN_DASHBOARD } from '@/store/modules/admin/resources/adminDashboard/adminDashboardActions.type';

export default {
  name: 'AdminDashboard',
  components: {
    BarChart,
    LineChart,
  },
  data: () => ({
    usersChartData: {
      labels: [],
      datasets: [
        {
          label: 'Users with 0 tabs',
          backgroundColor: '#ad5114',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [],
        },
        {
          label: 'Total Users',
          backgroundColor: '#1470AD',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [],
        },
      ],
    },
    userTabsChartData: {
      labels: [],
      datasets: [
        {
          label: 'User Tabs with 0 Cells',
          backgroundColor: '#ad5114',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [],
        },
        {
          label: 'User Tabs with unmapped files',
          backgroundColor: '#1470AD',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [4576, 7890, 12345, 18435, 23456, 34567, 45678],
        },
        {
          label: 'Total User Tabs',
          backgroundColor: '#007027',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [],
        },
      ],
    },
    userTabCellsChartData: {
      labels: [],
      datasets: [
        {
          label: 'Tab Cells with site_id 0',
          backgroundColor: '#ad5114',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [],
        },
        {
          label: 'Total Tab Cells',
          backgroundColor: '#1470AD',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [],
        },
      ],
    },
    favLinesChartData: {
      labels: [],
      datasets: [
        {
          label: 'Unlinked Fav Lines',
          backgroundColor: '#ad5114',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [],
        },
        {
          label: 'Total Fav Lines',
          backgroundColor: '#1470AD',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [],
        },
      ],
    },
    sitesChartData: {
      labels: [],
      datasets: [
        {
          label: 'Unlinked Sites',
          backgroundColor: '#ad5114',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [],
        },
        {
          label: 'Total Sites',
          backgroundColor: '#1470AD',
          pointBackgroundColor: '#F93EDE',
          borderWidth: 1,
          pointBorderColor: '#F93EDE',
          data: [],
        },
      ],
    },
    chartOptions: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
            },
            gridLines: {
              display: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
            },
          },
        ],
      },
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
    },
    userStatistics: {},
    userTabsStatistics: {},
    userTabCellsStatistics: {},
    siteStatistics: {},
    unlistedSitesStatistics: {},
    mostPopularSitesStatistics: {},
    favPagesStatistics: {},
    favLinesStatistics: {},
    favCellsStatistics: {},
  }),
  methods: {
    getDashboardData() {
      this.$store.dispatch(FETCH_ADMIN_DASHBOARD)
        .then((response) => {
          this.userStatistics = response.general.userStatistics;
          this.userTabsStatistics = response.general.userTabsStatistics;
          this.userTabCellsStatistics = response.general.userTabCellsStatistics;
          this.siteStatistics = response.general.siteStatistics;
          this.unlistedSitesStatistics = response.general.unlistedSitesStatistics;
          this.mostPopularSitesStatistics = response.general.mostPopularSitesStatistics;
          this.favPagesStatistics = response.general.favPagesStatistics;
          this.favLinesStatistics = response.general.favLinesStatistics;
          this.favCellsStatistics = response.general.favCellsStatistics;

          this.usersChartData.labels = response.usersGraph.labels;
          this.usersChartData.datasets[0].data = response.usersGraph.noTabsCount;
          this.usersChartData.datasets[1].data = response.usersGraph.totalCount;

          this.userTabsChartData.labels = response.userTabsGraph.labels;
          this.userTabsChartData.datasets[0].data = response.userTabsGraph.withNoCellsCount;
          this.userTabsChartData.datasets[1].data = response.userTabsGraph.withNoMappedFilesCount;
          this.userTabsChartData.datasets[2].data = response.userTabsGraph.totalCount;

          this.userTabCellsChartData.labels = response.userTabCellsGraph.labels;
          this.userTabCellsChartData.datasets[0].data = response.userTabCellsGraph.withSiteId0Count;
          this.userTabCellsChartData.datasets[1].data = response.userTabCellsGraph.totalCount;

          this.favLinesChartData.labels = response.favLinesGraph.labels;
          this.favLinesChartData.datasets[0].data = response.favLinesGraph.notLinkedCount;
          this.favLinesChartData.datasets[1].data = response.favLinesGraph.totalCount;

          this.sitesChartData.labels = response.sitesGraph.labels;
          this.sitesChartData.datasets[0].data = response.sitesGraph.notLinkedCount;
          this.sitesChartData.datasets[1].data = response.sitesGraph.totalCount;

          this.$log.info('FETCH_ADMIN_DASHBOARD', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_ADMIN_DASHBOARD', error);
        });
    },
  },
  mounted() {
    this.getDashboardData();
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/admin/admin-dashboard.scss';
</style>
