<script>
import { Line } from 'vue-chartjs';
// https://vue-chartjs.org/guide/#introduction

export default {
  name: 'LineChart',
  extends: Line,
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
};
</script>
